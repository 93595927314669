import React, {useEffect, useState} from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { Loader, Segment } from 'semantic-ui-react';
import { hashHistory } from 'react-router';
import { withAppContext } from '@utils/contexts';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import { FormattedMessage, injectIntl } from 'react-intl';
import categoryUtils, { withSearchResultsFilter } from '@utils/categoryUtils';
import { connect } from 'react-redux';
import {
    CALENDAR_DATE_FORMAT_BE,
    CALENDAR_DATE_FORMAT_FE,
    CATEGORY_NAMES,
    COMPANY_CHECK_NEWS_LANGUAGES_SELECTION,
    SNAPSHOT_NEGATIVE_TERMS_HEADERS,
    PERSON_CHECK_NEWS_LANGUAGES_SELECTION,
    PERSON_SEARCH,
    PREFERENCES_KEY,
    POST_FILTER_TERMS,
    DISPLAY_RISK_SCORES,
} from '@constants';
import { ACTION_TYPES, EVENT_SUBTYPES } from '@sagas/constants/investigationConstants';
import costCodeUtils from '@utils/costCodeUtils';
import { TagPill } from '@reusable/Table/components/defaults';
import errorUtils from '@utils/errors/error-utils';
import { bindActionCreators, compose } from 'redux';
import investigationActions from '../redux/Investigation.actions';
import NegativityTermsContainer from './negativeNewsVisualisations/negativityTerms/NegativityTermsContainer';
import NegativityTermSnapshotItem from './negativeNewsVisualisations/negativityTerms/negativityTermsItems/NegativityTermSnapshotItem';
import NegativeNewsVisualisationsUtils from './negativeNewsVisualisations/resultsList/NegativeNewsVisualisationsUtils';
import NegativeNewsDonutChart from './negativeNewsVisualisations/snapshot/donutChart/NegativeNewsDonutChart';
import SanctionsPodContent from './sanctionsRisk/snapshot/SanctionsPodContent/SanctionsPodContent';
import searchResultsActions from '../redux/SearchResults.actions';
import postFilterConfigActions from '../redux/PostFilterConfiguration.actions';
import actions from '@UserPreferences/redux/UserPreferences.actions';
import { isEmpty } from 'lodash';
import searchUtils, { getSanctionsTableData } from '../SearchUtils';
import withHistoryEvents from './hoc/WithHistoryEvents';
import HistoryEventsProvider from './providers/HistoryEventsProvider';
import EsgRatingsDonutChart from '@EsgRatings/components/EsgRatingsDonutChart';
import { Link } from 'react-router';
import { ESG_RATINGS_VISUALISATIONS } from '@constants';
import usePrevious from "@scripts/hooks/usePrevious";

export const CountPods = (props) => {

    const [refreshSelectedNegativityLevels, setRefreshSelectedNegativityLevels] = useState(null); 
    const prevDisplayRiskScores = usePrevious(props.displayRiskScores);

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    // compared to the Negative News pod, which gets all the information from a single endpoint no matter the displayRiskScore flag value, the Sanctions pod gets the risk score and the documents within the table from a separate endpoint
    // this useEffect is used to get the data for the Sanctions pod when changing the displayRiskScore flag from the Snapshot page to SHOW by triggering this second endpoint call to get the risk score and the documents within the table
    // (the first endpoint call only retrieves the count)
    useEffect(() => {
        if (prevDisplayRiskScores && prevDisplayRiskScores !== DISPLAY_RISK_SCORES.SHOW && props.displayRiskScores === DISPLAY_RISK_SCORES.SHOW && props.categoryName === CATEGORY_NAMES.SANCTIONS_WATCHLIST && !sanctionsRisk.riskLevel && !sanctionsRisk.documents.length) {
            getSanctionsTableData(props.searchResults[CATEGORY_NAMES.SANCTIONS_WATCHLIST].postFilters);
        }
    }, [props.displayRiskScores]);

    const showResultList = (event, category) => {
        let categoryName = event && event.target.value ? event.target.value : category,
            visitedContentTypes = props.investigation.visitedContentTypes;

        if (categoryUtils.hasChildren(categoryName)) {
            categoryName = withSearchResultsFilter(props.searchResults).getFirstChild(categoryName).key;
        }
        if (visitedContentTypes && !visitedContentTypes[categoryName]) {
            let billingId = props.investigation.billingId,
                costCode = costCodeUtils.getCostCode();

            props.sendInvestigationAction({
                type: EVENT_SUBTYPES.counts,
                payload: { sendEventWithoutTriggeringTheFlow: true },
            });

            if (props.onBillingEvent) {
                props.onBillingEvent(billingId, costCode);
            }
        }

        hashHistory.push(props.location + '&list=true' + '&category=' + categoryName);
    };
    let dateRange = '';

    if (props.dateRange) {
        if (utils.dateRangeToText(props.dateRange)) {
            dateRange = formatRichMessage({ id: 'General.DateRange.' + props.dateRange }, props.intl);
        } else {
            let customDates = props.dateRange.split(';');

            if (customDates[0]) {
                const fromLabel = formatRichMessage({ id: 'General.range.from' }, props.intl);
                let parsedDate = utils.getDateFromString(customDates[0], CALENDAR_DATE_FORMAT_BE);
                let dateFormatted = utils.getUserLocaleDateWithoutTimezone(parsedDate).format(CALENDAR_DATE_FORMAT_FE);
                dateRange += fromLabel + ' ' + dateFormatted + ' ';
            }
            if (customDates[1]) {
                const toLabel = formatRichMessage({ id: 'General.range.to' }, props.intl);
                let parsedDate = utils.getDateFromString(customDates[1], CALENDAR_DATE_FORMAT_BE);
                let dateFormatted = utils.getUserLocaleDateWithoutTimezone(parsedDate).format(CALENDAR_DATE_FORMAT_FE);
                dateRange += toLabel + ' ' + dateFormatted;
            }
        }
    }

    const downloadResultList = (...args) => {
        if (!props.downloadDisabled) {
            props.downloadResultList(...args);
        }
    };

    const handleNegativeTermClick = (term, languageCode, negativeNewsCategory) => {
        const categoryData = props.searchResults[negativeNewsCategory];
        // Update postFilter with selected value
        props.updatePostFilterForCategory(negativeNewsCategory, POST_FILTER_TERMS, [term]);
        showResultList(null, negativeNewsCategory);

        props.sendInvestigationAction({
            type: ACTION_TYPES.handleNegativeTermFilter,
        });

        if (categoryUtils.isVisitedCategory(categoryData, props.investigation)) {
            return searchUtils
                .loadMoreDocumentsForAvailableCategories(
                    negativeNewsCategory,
                    0,
                    false,
                    props.isNewResearchSummaryEnabled
                )
                .then(() => {
                    props.markCategoryAsVisited(negativeNewsCategory);
                    props.historyEventsProviderProps.updateHistoryEntry(
                        negativeNewsCategory,
                        props.categoryLocation.query.filteredFields
                    );
                });
        }
    };

    // props
    const {
        intl,
        searchType,
        displayRiskScores,
        categoryName,
        negativityRisk,
        isNew,
        hasTag,
        language,
        value,
        sanctionsRisk,
    } = props;

    const hasAcceptedRiskScores = displayRiskScores === DISPLAY_RISK_SCORES.SHOW;
    const isNegativeNewsPod = categoryName === CATEGORY_NAMES.NEGATIVE_NEWS;
    const shouldDisplayNegativeNewsVisualisations = isNegativeNewsPod && hasAcceptedRiskScores;
    const hasNegativeNewsVisualisationsResults =
        shouldDisplayNegativeNewsVisualisations && value > 0 && negativityRisk.documentsCount;
    const riskClass = `${negativityRisk && negativityRisk.overallRiskLevel ? 'hasRisk' : ''}`;
    const riskLevelClass = `${negativityRisk && negativityRisk.overallRiskLevel ? negativityRisk.overallRiskLevel.toLowerCase() : ''
        }`;
    const preferencesRedirect =
        searchType === PERSON_SEARCH ? PERSON_CHECK_NEWS_LANGUAGES_SELECTION : COMPANY_CHECK_NEWS_LANGUAGES_SELECTION;
    const disclaimerLabel = formatRichMessage({ id: 'NegativeNewsRisk.label.disclaimer' }, intl, {
        a: (...chunks) =>
            `<a data-testid='negativenews-preferences-link' href=#/user-preferences?section=${preferencesRedirect}>${chunks.join(
                ''
            )}</a>`,
    });
    const topNegativeTerms = NegativeNewsVisualisationsUtils.processTopNegativeTerms(props.topNegativeTerms);
    const shouldDisplayDonutChart =
        negativityRisk && negativityRisk.documentsCount && !isEmpty(negativityRisk.documentsCount);
    const shouldDisplayTag = isNew || hasTag;
    const userFormattedCount = utils.formatNumbersByUserLocale(language, value);
    const matomoTrackingPropertiesCategoryDownloadIcon = {
        'data-gm-u-pn': 'nexisDiligence_snapshot',
        'data-gm-u-c': 'categoryPod',
        'data-gm-u-sc': 'categoryPodDownloadIcon',
        'data-gm-u-cc': `pod_${categoryName}`,
        'data-gm-u-e': 'delivery_download',
        'data-gm-u-v': 'download',
        'data-gm-u-a': 'click',
    };
    const matomoTrackingPropertiesCategoryCountClick = {
        'data-gm-u-pn': 'nexisDiligence_snapshot',
        'data-gm-u-c': 'categoryPod',
        'data-gm-u-sc': 'categoryCount',
        'data-gm-u-cc': `pod_${categoryName}`,
        'data-gm-u-e': 'pod_click',
        'data-gm-u-v': value,
        'data-gm-u-a': 'click',
    };

    const isSanctionsPod = categoryName === CATEGORY_NAMES.SANCTIONS_WATCHLIST;
    const displaySanctionsPod = hasAcceptedRiskScores &&
        sanctionsRisk.riskLevel &&
        !!sanctionsRisk.documents.length &&
        isSanctionsPod;
    const sanctionsRiskLevel = sanctionsRisk?.riskLevel?.toLowerCase();

    return (
        <div className={`card-wrapper ${hasNegativeNewsVisualisationsResults || displaySanctionsPod ? 'hasRisk' : ''}`}>
            {!props.negativityRisk.overallRiskLevel ?
                <ReactTooltip
                    id="negativity-unavailable"
                    type="light"
                    event="mouseenter"
                    eventOff="mouseleave"
                    border={true}
                    effect="solid"
                    className="tooltips"
                    placec="left"
                /> : <></>}
            <div className={`card ${shouldDisplayTag ? 'new' : ''}`} id={`automation-card-${props.title}`}>
                <div className="card-title-wrap">
                    <div className="card-title">
                        <h2 className="card-title__label" data-track={`card-title__label__${props.title}`}>
                            <FormattedMessage
                                id={'General.categoryName.label.' + props.title}
                                values={props.values || {}}
                            />
                        </h2>
                        {shouldDisplayTag && <TagPill label={props.tagLabel} />}
                        {props.hasFeedbackLink && (
                            <div
                                data-track={`${props.categoryName}_source_send-feedback-link`}
                                className={`send-feedback-link`}
                            >
                                <FormattedMessage id={'ResultsSnapshot.sendFeedback'} />
                            </div>
                        )}
                    </div>
                    <div className="download-box-container">
                        {props.categoryName === CATEGORY_NAMES.ESG_RATINGS && props.overallRating && (
                            <img className="esg-download-logo" src="/assets/Group_162430.png" alt="CSRHUB logo" />
                        )}
                        <button
                            className="icon-box"
                            value={props.categoryName}
                            onClick={downloadResultList}
                            disabled={!props.loading || props.downloadDisabled}
                            {...matomoTrackingPropertiesCategoryDownloadIcon}
                        >
                            <div className="la-Download" data-track={`${props.categoryName}-download-button`} />
                        </button>
                    </div>
                </div>
                <div className={`podContent ${hasNegativeNewsVisualisationsResults ? 'withNegativeNewsRisk' : ''}`}>
                    <div className="count-container">
                        {!props.loading ? (
                            <Segment basic>
                                {props.value === 'N/A' ? (
                                    <h2>
                                        <FormattedMessage id="General.label.notAvailable" />
                                    </h2>
                                ) : (
                                    <Loader size="large" active />
                                )}
                            </Segment>
                        ) : (
                            <div
                                className={`display-count ${isNegativeNewsPod ? 'isNegativeNewsPod' : ''} ${hasNegativeNewsVisualisationsResults ? 'withNegativeNewsRisk' : ''
                                    }${displaySanctionsPod ? 'isSanctionsPod' : ''}`}
                            >
                                <div className="display-count-value" data-track="esg_ratings-overall-information">
                                    {props.categoryName === CATEGORY_NAMES.ESG_RATINGS &&
                                        props.overallRating &&
                                        props.loading ? (
                                        <div className="chart-container">
                                            <EsgRatingsDonutChart
                                                size={ESG_RATINGS_VISUALISATIONS.POD_COUNT_DONUT.SIZE}
                                                fontSize={ESG_RATINGS_VISUALISATIONS.POD_COUNT_DONUT.FONT_SIZE}
                                                titleColor={ESG_RATINGS_VISUALISATIONS.POD_COUNT_DONUT.TITLE_COLOR}
                                                rating={props.overallRating}
                                                onChartClick={props.onChartClick}
                                                ratingCategory={props.ratingRange}
                                            />
                                            <div className="chart-right-part">
                                                <div
                                                    className="text-info"
                                                    dangerouslySetInnerHTML={{
                                                        __html: formatRichMessage(
                                                            { id: 'ResultsSnapshot.esgRating.textInfo' },
                                                            props.intl
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : isSanctionsPod && displaySanctionsPod ? (
                                        <div className="sanctions-pod-count-container">
                                            <button
                                                className="link-button"
                                                value={props.categoryName}
                                                onClick={() => showResultList(undefined, props.categoryName)}
                                                data-testid={`show-results-list-${props.categoryName}`}
                                                data-track={`show-results-list-${props.categoryName}`}
                                                {...matomoTrackingPropertiesCategoryCountClick}
                                            >
                                                <div className="sanctions-pod-count">{userFormattedCount}</div>
                                            </button>
                                            <div
                                                className={`risk-score-container ${sanctionsRiskLevel} non-clickable`}
                                            >
                                                <div className={`doughnut ${sanctionsRiskLevel}`} />
                                               <p className="risk-score">
                                                   <FormattedMessage id= {`SanctionsAndWatchlists.risk.table.${sanctionsRiskLevel.toLowerCase()}` } />
                                               </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <button
                                            className="link-button"
                                            value={props.categoryName}
                                            onClick={showResultList}
                                            data-testid={`show-results-list-${props.categoryName}`}
                                            data-track={`show-results-list-${props.categoryName}`}
                                            {...matomoTrackingPropertiesCategoryCountClick}
                                        >
                                            {userFormattedCount}
                                        </button>
                                    )}

                                    {props.hasError && (
                                        <div className="display-error">
                                            <span className="la-Error icon" />
                                        </div>
                                    )}
                                </div>
                                {!isEmpty(props.negativityRisk)
                                    ? shouldDisplayNegativeNewsVisualisations && (
                                        <div className="display-count-negativity-risk">
                                            <p className="display-count-negativity-risk-title">
                                                <FormattedMessage id="NegativeNewsRisk.label.newsRisk" />
                                            </p>
                                            <p
                                                className={`display-count-negativity-risk-level ${riskClass} ${riskLevelClass}`}
                                            >
                                                {props.negativityRisk.overallRiskLevel ? (
                                                    <span data-track="negativity-level-overall-risk">
                                                        <FormattedMessage
                                                            id={`NegativeNewsVisualisations.label.${props.negativityRisk.overallRiskLevel.toLowerCase()}`}
                                                        />
                                                    </span>
                                                ) : (
                                                    <span data-track="negativity-level-overall-risk-not-available" data-for="negativity-unavailable" data-tip={`${formatRichMessage({ id: 'ResultsSnapshot.negativeNewsRating.negativityUnavailable.tooltip' }, props.intl)}`}>
                                                        <FormattedMessage id="General_CoreFunctionality_UIText_general.notAvailable" />
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                    )
                                    : null}
                            </div>
                        )}
                        {shouldDisplayNegativeNewsVisualisations && (
                            <div className="card-disclaimer">
                                <div dangerouslySetInnerHTML={{ __html: disclaimerLabel }} />
                            </div>
                        )}
                    </div>
                    {hasNegativeNewsVisualisationsResults && shouldDisplayDonutChart && (
                        <NegativeNewsDonutChart 
                            {...props} onSegmentClick={showResultList} 
                            refreshSelectedNegativityLevels={refreshSelectedNegativityLevels} 
                            updateRefreshSelectedNegativityLevels={setRefreshSelectedNegativityLevels}
                        />
                    )}
                    {hasNegativeNewsVisualisationsResults && (
                        <div className="terms-container">
                            <NegativityTermsContainer
                                negativeTermsHeaders={SNAPSHOT_NEGATIVE_TERMS_HEADERS}
                                isSnapshot={true}
                            >
                                {topNegativeTerms.map((topNegativeTerm, index) => {
                                    const termItem = {
                                        ...topNegativeTerm,
                                        count: utils.formatNumbersByUserLocale(props.language, topNegativeTerm.count),
                                    };

                                    return (
                                        <NegativityTermSnapshotItem
                                            termItem={termItem}
                                            key={index}
                                            handleNegativeTermClick={handleNegativeTermClick}
                                        />
                                    );
                                })}
                            </NegativityTermsContainer>
                        </div>
                    )}
                    {displaySanctionsPod && props.loading && <SanctionsPodContent sanctionsRisk={sanctionsRisk} />}
                </div>
                {props.dateRange && (
                    <div className="display-date-range">
                        <div className="horizontal-divider" />
                        <p>{dateRange}</p>
                    </div>
                )}
                {props.categoryName === CATEGORY_NAMES.ESG_RATINGS && props.overallRating && (
                    <div className="display-date-range redirect-info">
                        <Link onClick={props.onChartClick}>
                            <FormattedMessage id={'ResultsSnapshot.esgRating.scoreCard'} />
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

CountPods.propTypes = {
    isNew: PropTypes.bool,
    tagLabel: PropTypes.string,
    hasTag: PropTypes.bool,
    hasFeedbackLink: PropTypes.bool,
    downloadDisabled: PropTypes.bool,
    hasError: PropTypes.bool,
};

CountPods.defaultProps = {
    isNew: false,
    tagLabel: '',
    hasTag: false,
    hasFeedbackLink: false,
    downloadDisabled: false,
    hasError: false,
};

const mapStateToProps = (state, { categoryName }) => {
    const searchType = state.user.preferences.generalSettings.searchType;
    const searchTypePrefsKey = PREFERENCES_KEY[searchType];

    return {
        searchResults: state.searchResults,
        investigation: state.investigation,
        isNew: categoryUtils.isNewCategory(categoryName),
        hasError: errorUtils.failedCategoriesCollector.hasError(categoryName),
        isNewResearchSummaryEnabled: state.user.useNewResearchSummaryFeature,
        searchQuery: state.searchParams.query,
        searchType,
        prefilterQuery: state.searchParams.prefilterQuery,
        negativityRisk: state.negativeNewsVisualisation.negativityRisk,
        topNegativeTerms: state.negativeNewsVisualisation.topNegativeTerms,
        negativityLevels: state.user.preferences[searchTypePrefsKey].negativityLevel,
        language: state.user.preferences.language,
        displayRiskScores: state.user.preferences.generalSettings.displayRiskScores,
        popupModel: state.popupModel,
        sanctionsRisk: state.sanctionsRisk,
    };
};

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            markCategoryAsVisited: investigationActions.markVisitedCategories,
            sendInvestigationAction: investigationActions.investigationAction,
            updatePostFilterForCategory: searchResultsActions.updatePostFilterForCategory,
            updatePostFilterConfig: postFilterConfigActions.updatePostFilterConfigValues,
            updateRiskScores: actions.updateRiskScores,
        },
        dispatch
    );
};

export default compose(
    injectIntl,
    withAppContext,
    connect(mapStateToProps, mapDispatchToProps),
    withHistoryEvents(HistoryEventsProvider)
)(CountPods);
